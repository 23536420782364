.hero {
  display: flex;
  align-items: center;

  height: 90vh;
  position: relative;
  padding-left: vw(135px);
  padding-right: vw($pb);
  padding-top: vw($pb);
  margin-bottom: vw(450px);
  @include media(1400px) {
	margin-bottom: vw(350px);
  }
  @include media($mobile) {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: $ps;
    height: auto;
  }

  &__item {
	width: 100%;
	position: relative;
    &:nth-child(1) {
      margin-top: 50%;
      max-width: 567px;
      margin-right: vw(90px);
      @include media($mobile) {
        margin-top: 0;
	  }
	  .placeholder__cover {
		  top:-60px;
	  }
    }
  }
  .scroll-down {
    position: absolute;
    bottom: 55px;
    left: 50%;
    transform: translateX(-50%);
  }
  &__image {
    width: 100%;
    &--top {
      top: -60px;
	  position: relative;
	  @include media($mobile) {
		  top: 0;
	  }
    }
  }
  &__text {
    position: relative;
    max-width: 250px;
    margin-right: vw(90px);
    padding-top: 50px;
    margin-top: vw(450px);
    p {
      padding-bottom: 30px;
    }
    @include media($mobile) {
      padding-top: 30px;
	  margin-top: 0;
		margin-bottom: $ps;
	  
    }
  }
  &__title {
    text-align: right;
    position: absolute;
    width: 1000px;
    font-size: 6vw;
    right: 0;
    top: 0;
    transform: translateY(-100%);
	line-height: 1;
	z-index: 4;
	@include media($mobile) {
		position: relative;
		transform: translateY(0);
		width: auto;
		text-align: left;
		margin-bottom: $ps;
		margin-top: $ps;
	}
    @include media($phone) {
      font-size: 10vw;
    }
  }
}
.intro {
  margin-bottom: 80px;
  &-item {
    margin-bottom: $pb;
	position: relative;
	&__image {
		@include media($mobile) {
			img {
				width: 100%;
			}
		}
	}
    @include media($mobile) {
      &:not(:last-child) {
        margin-bottom: $ps;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    &--left {
      @include media($mobile, min) {
        margin-left: vw($pb);
      }
    }
  }
}
.portfolio {
  &__items {
    margin-bottom: 190px;
    @include media($mobile) {
      padding: 0 5%;
	  margin-bottom: $pb;
	  display: flex;
	  flex-direction: column-reverse;
	}
	&--stay {
		@include media($mobile) {
			flex-direction: column!important;
			.grid__item {
				display: flex;
				flex-direction: column-reverse;
			}
		}
	}
  }
  &-item {
    margin-bottom: $pm;
    position: relative;
    @include media($mobile) {
		padding-left: 0;
		padding-right: 0;
      &:not(:last-child) {
        margin-bottom: $ps;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    img {
      width: 100%;
    }

    &__image {
      &--end {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        img {
          max-width: 542px;
        }
      }
    }
    &__text {
      max-width: 480px;
      h2 {
        margin-bottom: $gap;
      }
      &--left {
        margin-left: vw(130px);
        margin-bottom: vw(160px);
        @include media($mobile) {
          margin-bottom: vw(70px);
          margin-left: vw(70px);
        }
      }

      &--right-s {
        margin-right: $ps;
      }
      &--top {
        margin-top: vw(160px);
        &-2 {
          margin-top: vw(100px);
        }
      }
      &--bottom {
        margin-bottom: vw(130px);
      }

      &--smaller {
        max-width: 340px;
      }
      @include media($mobile) {
        margin: 0 0 $pm;
      }

      @include media($phone) {
        br {
          display: none;
        }
      }
    }
  }
}
[data-aoe] {
	// opacity: 1!important;
}
.placeholder {
  &__image {
    transition: $transition;
    opacity: 0;
  }
  &__cover {
    transition: $transition;
pointer-events: none;
    @include center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $light;
    z-index: 3;
    img,
    svg {
	  width: 15%;
	  height: 15%;
	  opacity: 0.3;
    }
  }
  &.loaded {
    .placeholder__cover {
      opacity: 0;
    }
    .placeholder__image {
      opacity: 1;
    }
  }
}

.about {
  &__body {
	max-height: 820px;
	padding: 60px 0;
    background-image: url("./../img/cos.svg");
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: vw(90px);
    @include media($mobile) {
      margin-left: 0;
      padding: 70px 0;
      height: auto;
    }
  }
  &__text {
    max-width: 842px;
    margin-right: vw(280px);
    background: #fff;
    padding: 50px 140px;
    @include media($mobile) {
      padding: 30px;
      margin-right: 0;
    }
  }
}
