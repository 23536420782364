.footer {
  &__container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    height: 100vh;
	margin: 0 auto;
	padding: 0 70px;
    max-width: 1140px;
    @include media($mobile) {
      padding: 0 5%;
    }
  }
  &__header {
    margin: 0 auto 80px auto;
  }
  &__top {
	margin-top: vw(250px);
	@include media($mobile) {
		margin-top: 15vh;
	}
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: vw(115px);
    @include media($mobile) {
      flex-direction: column-reverse;
      .author {
        margin-top: 30px;
      }
    }
  }
}
.contact {
  display: flex;
  flex-direction: column;
  &__link {
	font-size: 1.75rem;
	font-weight: 700;
	
    &:not(:last-child) {
      margin-bottom: 30px;
    }
    @include media($mobile) {
      font-size: 1.425rem;
    }
  }
}

.author {
  margin-bottom: 10px;
  &__name {
  }
}
.social-media {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 320px;
  @include media(360px) {
    margin: 0 auto;
    width: 100%;
  }
  height: 24px;
  &__link {
    text-decoration: unset;
    transition: $transition;
    path {
      transition: $transition;
    }
    &:hover {
      transform: scale(1.1);
      path {
        fill: $secondary;
      }
    }
  }
}
