$primary:#53627c;
$dark: #000000;
$secondary: #0503fe;;
$white: #fdfdfd;
$light:#f0f2f8;


// timings
$timing: cubic-bezier(.2, 1.14, 1, 1);
$transition: all 1s $timing;

// paddings
$gap: 1rem;
$ps: 30px;
$pm: 50px;
$pb: 70px;

$mobile: 1023px;
// breakpoints
$phone: 740px;
$tablet: $mobile;
$desktop: 1441px;

// overall mobile break

// break grid columns
$colBreak: $tablet;

// define with of project (in px but dont include string) to recalculate px to vw;
$projectWidth: 1920;
// project grid size
$gridWidth: 100%;

// top layer z-index
$toplayer: 100;

// header height

// mixins
@import 'base/mixins';

// render que

@import 'base/reset';
@import 'base/typography';

@import 'layout/grid';
@import 'layout/header';


@import 'base/common';

@import 'views/page-home';

@import 'components/buttons';
@import 'components/forms';

@import 'layout/footer';
@import './../css/vendor/swiper.scss';