@font-face {
  font-family: heyWowRegular;
  src: url("./../fonts/web/HeyWow-Regular.woff");
}
@font-face {
  font-family: heyWowBold;
  src: url("./../fonts/web/HeyWow-Bold.woff");
}
html {
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  font-size: 1rem;
}

body {
  font-family: heyWowRegular, sans-serif;
  color: $primary;
}

$giant: 4rem;
$big: 3rem;
$title: 3rem;
$med: 1.125rem;
$small: 0.875rem;

h1,
.h1 {
  font-size: $big;
  color: $dark;
  font-family: heyWowBold, sans-serif;
  @include media($phone) {
    font-size: 2rem;
  }
}

h2,
.h2 {
  color: $dark;
  font-size: $title;
  font-family: heyWowBold, sans-serif;
  @include media($phone) {
    font-size: 1.8rem;
  }
}

h3,
.h3 {
  color: $dark;
  font-size: $med;
  font-family: heyWowBold, sans-serif;
}

h4,
.h4 {
  color: $dark;
  font-size: $med;
  font-family: heyWowBold, sans-serif;
}

.small {
  font-size: $small;
}
.big {
  font-size: $giant;
  @include media($phone) {
    font-size: 2.2rem;
  }
}

.text {
  img {
    margin-bottom: 20px;
  }

  h1,
  h2,
  h3 {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
  p {
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
  &--white {
    color: $white;
  }
}
p {
  line-height: 1.688rem;
}

a {
  color: #0503fe;
  text-decoration: underline;
}
