:root {
	--swiper-theme-color: #333;
}

.swiper-container {
	margin-left: auto;
	margin-right: auto;
	position: relative;
	overflow: hidden;
	list-style: none;
	padding: 0;
	/* Fix of Webkit flickering */
	z-index: 1;
	width: 100%;
}

.swiper-container-vertical>.swiper-wrapper {
	flex-direction: column;
}

.swiper-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: flex;
	transition-property: transform;
	box-sizing: content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
	transform: translate3d(0px, 0, 0);
}

.swiper-container-multirow>.swiper-wrapper {
	flex-wrap: wrap;
}

.swiper-container-multirow-column>.swiper-wrapper {
	flex-wrap: wrap;
	flex-direction: column;
}

.swiper-container-free-mode>.swiper-wrapper {
	transition-timing-function: ease-out;
	margin: 0 auto;
}

.swiper-slide {
	flex-shrink: 0;
	width: 100%;
	height: 100%;
	position: relative;
	transition-property: transform;
}

.swiper-slide-invisible-blank {
	visibility: hidden;
}

/* Auto Height */
.swiper-container-autoheight {

	&,
	.swiper-slide {
		height: auto;
	}

	.swiper-wrapper {
		align-items: flex-start;
		transition-property: transform, height;
	}
}

/* 3D Effects */
.swiper-container-3d {
	perspective: 1200px;

	.swiper-wrapper,
	.swiper-slide,
	.swiper-slide-shadow-left,
	.swiper-slide-shadow-right,
	.swiper-slide-shadow-top,
	.swiper-slide-shadow-bottom,
	.swiper-cube-shadow {
		transform-style: preserve-3d;
	}

	.swiper-slide-shadow-left,
	.swiper-slide-shadow-right,
	.swiper-slide-shadow-top,
	.swiper-slide-shadow-bottom {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		z-index: 10;
	}

	.swiper-slide-shadow-left {
		background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	}

	.swiper-slide-shadow-right {
		background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	}

	.swiper-slide-shadow-top {
		background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	}

	.swiper-slide-shadow-bottom {
		background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	}
}

/* CSS Mode */
.swiper-container-css-mode {
	>.swiper-wrapper {
		overflow: auto;
		scrollbar-width: none;
		/* For Firefox */
		-ms-overflow-style: none;

		/* For Internet Explorer and Edge */
		&::-webkit-scrollbar {
			display: none;
		}
	}

	>.swiper-wrapper>.swiper-slide {
		scroll-snap-align: start start;
	}
}

.swiper-container-horizontal.swiper-container-css-mode {
	>.swiper-wrapper {
		scroll-snap-type: x mandatory;
	}
}

.swiper-container-vertical.swiper-container-css-mode {
	>.swiper-wrapper {
		scroll-snap-type: y mandatory;
	}
}

.swiper-button {
	height: 10px;

	&-next,
	&-prev {
		width: 30px;
		height: 30px;
		background: #ccc;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 2;
		cursor: pointer;
	}

	&-prev {
		left: 5%;
	}

	&-next {
		right: 5%;
	}
}

.swiper-pagination {
	position: absolute;
	bottom: 20px;
	left: 20px;
	z-index: 2;
	display: flex;

	&-bullet {
		display: block;
		width: 10px;
		height: 10px;
		background: #ccc;
		margin-right: 5px;

		&-active {
			background: red;
		}
	}
}