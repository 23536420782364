body {
	overflow-x: hidden;
}

.container {
	@include grid;

	@include media($mobile) {
		padding: 0 5%;
	}

	&--left {
		margin-left: 5%;

		@include media($gridWidth + 50, min) {
			margin-left: calc(50% - (#{$gridWidth} / 2));
		}
	}

	&--right {
		margin-right: 5%;

		@include media($gridWidth + 50, min) {
			margin-right: calc(50% - (#{$gridWidth} / 2));
		}
	}
}
.gap {
	padding: $gap;

	&-r {
		padding-right: $gap;

		&-s {
			padding-right: $ps;
		}

		&-m {
			padding-right: $pm;
		}
		&-b {
			padding-right: $pb;
		}
	}

	&-l {
		padding-left: $gap;

		&-s {
			padding-left: $ps;
		}

		&-m {
			padding-left: $pm;
		}
		&-b {
			padding-left: $pb;
		}
	}

	&-b {
		padding-bottom: $gap;

		&-s {
			padding-bottom: $ps;
		}

		&-m {
			padding-bottom: $pm;
		}
	}

	&-t {
		padding-top: $gap;

		&-s {
			padding-top: $ps;
		}

		&-m {
			padding-top: $pm;
		}
		&-b {
			padding-top: $pb;
		}
		&-g {
			padding-top: 100px;
		}
	}
}
.grid {
	display: grid;
	width: 100%;

	@for $i from 1 to 13 {
		&--#{$i} {
			@include media($colBreak, min) {
				grid-template-columns: repeat(#{$i}, auto);
			}
		}
	}

	&__item {
		padding-top: 0;
		&--end {
			flex-direction: column;
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;
		}
		&--shift-t {
			margin-top: vw(-140px);
		}
		&--top {
			position: relative;
			@include media($mobile,min) {
				top: -100px;
			}
		}
		&--smaller {
			max-width: 680px;
		}
	}

	&--gap {
		grid-row-gap: $gap;
		grid-column-gap: $gap;

		padding-bottom: $gap;

		&--small {
			padding-top: $gap;
			grid-row-gap: $ps;
			grid-column-gap: $ps;
		}

		&--med {
			padding-top: $gap;
			grid-row-gap: $pm;
			grid-column-gap: $pm;
			@include media($mobile) {
				grid-column-gap: 0;
				grid-row-gap: 0;
			}
		}

		&--big {
			padding-top: $gap;
			grid-row-gap: $pb;
			grid-column-gap: $pb;
		}
	}
}

.flex {
	display: flex;
	flex-wrap: wrap;

	&--center {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&--end {
		align-items: flex-end;
	}
	
	@include media($colBreak, min) {
		@for $i from 1 to 13 {
			&--#{$i} {
				width: calc(8.3333333% * #{$i});
			}
		}
	}
}

.align {

	&--start {
		align-items: flex-start;
	}

	&--center {
		align-items: center;
	}

	&--strech {
		align-items: stretch;
	}

	&--end {
		align-items: flex-end;
	}
}

.justify {
	&--start {
		justify-content: flex-start;
	}

	&--center {
		justify-content: center;
	}

	&--space {
		justify-content: space-between;
	}

	&--end {
		justify-content: flex-end
	}
}
